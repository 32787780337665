import Swiper from "swiper/bundle";

class SliderGallery {
	constructor() {
		this.sliderGallerySections =
			document.getElementsByClassName("slider-gallery");
		if (this.sliderGallerySections[0]) {
			this.init();
		}
	}

	/* eslint-disable class-methods-use-this, no-unused-vars */
	init() {
		const that = this;
		Array.from(this.sliderGallerySections).forEach((section) =>
			this.initSlider(section, that)
		);
	}

	initSlider(section, that) {
		/* eslint-disable no-unused-vars, no-undef */
		const sliderSection = new Swiper(".slider-gallery .swiper-container", {
			// Optional parameters

			spaceBetween: 24,
			freeMode: true,
			grabCursor: true,
			centeredSlides: true,
			slidesPerView: "auto",

			// Progress
			on: {
				progress(progress) {
					const swiper = section;
					that.progress(swiper, progress, section);
				},
			},

			// Navigation arrows
			navigation: {
				nextEl: ".js-slider-gallery-button-next",
				prevEl: ".js-slider-gallery-button-prev",
			},
		});
		/* eslint-enable no-unused-vars, no-undef */
	}
	/* eslint-enable class-methods-use-this, no-unused-vars */

	/* eslint-disable */
	progress(swiper, progress) {
		const currentProgressPercentage = progress.progress * 100;
		Array.from(swiper.querySelectorAll(".swiper-current-progress")).forEach(
			(el) => {
				el.style.width = currentProgressPercentage + "%";
			}
		);
	}
	/* eslint-enable */
}

export default SliderGallery;
