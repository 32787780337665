class Lottie {
	constructor() {
		this.init();
	}

	init() {
		this.animatedElementsContainers =
			document.getElementsByClassName("js-lottie");

		function loadLottie(
			animatedElementContainer,
			animationPath,
			animationLoop
		) {
			window.loadJS("/wp-content/themes/clariship/js/vendor/lottie.js", () => {
				window.lottie.loadAnimation({
					container: animatedElementContainer, // the dom element that will contain the animation
					renderer: "svg",
					loop: animationLoop,
					autoplay: true,
					path: animationPath, // the path to the animation json
					rendererSettings: {
						scaleMode: "noScale",
					},
				});
			});
		}

		if (this.animatedElementsContainers) {
			Array.prototype.forEach.call(
				this.animatedElementsContainers,
				(animatedElementContainer) => {
					const animationPath = animatedElementContainer.getAttribute(
						"data-animation-path"
					);

					const animationLoop = animatedElementContainer.getAttribute(
						"data-animation-loop"
					);

					if (animationPath !== undefined && animationPath !== null) {
						if (window.lottie === undefined) {
							loadLottie(
								animatedElementContainer,
								animationPath,
								animationLoop
							);
						} else {
							setTimeout(() => {
								loadLottie(
									animatedElementContainer,
									animationPath,
									animationLoop
								);
							}, 1000);
						}
					}
				}
			);
		}
	}
}

export default Lottie;
