/* eslint-disable */

// import intlTelInput from "intl-tel-input";

class Forms {
	constructor() {
		this.init();
	}

	init() {}

	newForm(form) {
		this.formPrivacyPolicy = form.querySelector("#newsletter-input-form");
		if (!this.formPrivacyPolicy) {
			this.formPrivacyPolicy = form.querySelector("#privacy-input-form");
		}
		this.formSendButton = form.querySelector(".contact-button");
		this.closeNotificationButton =
			document.getElementById("close-notification");
		// Load Re-captcha V3 or throw an error
		if (googleRecaptchaWebKey) {
			setTimeout(() => {
				// eslint-disable-line
				const script = document.createElement("script");
				script.setAttribute("type", "text/javascript");
				script.setAttribute("async", true);
				script.setAttribute(
					"src",
					`https://www.google.com/recaptcha/api.js?render=${googleRecaptchaWebKey}`
				); // eslint-disable-line
				if (
					!document.querySelector(
						'[src="https://www.google.com/recaptcha/api.js?render=' +
							googleRecaptchaWebKey +
							'"]'
					)
				) {
					document.getElementsByTagName("head")[0].appendChild(script);
				}
			}, 500);

			// Load international telephone input
			// this.loadPhoneInput(form);

			// Make sure the privacy policy value is unchecked
			this.formPrivacyPolicy.checked = false;
			this.formPrivacyPolicy.value = "unchecked";

			// Listen for privacy policy checkbox changes
			this.formPrivacyPolicy.addEventListener("change", this.checkboxToogle);

			// Prevent form submit
			form.addEventListener("submit", this.preventFormSubmit);

			// Form validations and submit
			this.formSendButton.addEventListener("click", this.submitValidateForm);

			// Close notificacion banner
			this.closeNotificationButton.addEventListener(
				"click",
				this.closeNotificationBanner
			);

			// Enable submit button
			this.formSendButton.disabled = false;
		} else {
			console.error("Set up the Google ReCaptcha V3 keys"); // eslint-disable-line no-console
		}
	}

	loadPhoneInput(form) {
		const input = document.querySelector("#phone");
		const countryData = window.intlTelInputGlobals.getCountryData();
		const phoneNumber = form.querySelectorAll("[name=phone_number]")[0];
		let currentLanguage = encodeURI(scriptsAjaxVariables.current_language); // eslint-disable-line

		if (countryData.length > 0) {
			for (let i = 0; i < countryData.length; i += 1) {
				const country = countryData[i];
				country.name = country.name.replace(/.+\((.+)\)/, "$1");
			}
		}

		if (currentLanguage === "en") {
			currentLanguage = "gb";
		}

		if (phoneNumber) {
			intlTelInput(input, {
				// Any initialisation options go here. Check https://github.com/jackocnr/intl-tel-input
				separateDialCode: "true",
				utilsScript: `https://${window.location.hostname}/wp-content/themes/hs-starter-theme/js/vendor/utils.js`,
				autoPlaceholder: "aggressive",
				initialCountry: `${currentLanguage}`,
				preferredCountries: [""],
			});
		}
	}

	checkboxToogle(checkbox) {
		if (checkbox.srcElement.value === "unchecked") {
			checkbox.srcElement.value = "checked";
		} else {
			checkbox.srcElement.value = "unchecked";
		}
	}

	preventFormSubmit(evt) {
		evt.preventDefault();
	}

	submitValidateForm(evt) {
		// Get the form parameters

		var action = this.form.querySelector(".action-type").value;
		var email = this.form.querySelectorAll("[name=email]")[0];
		const receiverEmail = this.form.querySelectorAll(
			"[name=receiver_email]"
		)[0];
		var privacyPolicy = this.form.querySelectorAll("[name=privacy_policy]")[0];
		var notificationsBanner = document.getElementById("notification-banner");
		var notificationMessage =
			document.getElementsByClassName("notification-text")[0];
		var currentLanguage = encodeURI(scriptsAjaxVariables.current_language); // eslint-disable-line
		var defaultLanguage = encodeURI(scriptsAjaxVariables.default_language); // eslint-disable-line
		var messageIsRequired = this.form.querySelectorAll(
			"[name=user_message_required]"
		)[0];
		var successMessage = this.form.querySelectorAll(
			"[name=success_message]"
		)[0];
		var errorMessage = this.form.querySelectorAll("[name=error_message]")[0];

		var firstName = false;
		var lastName = false;
		var phoneNumber = false;
		var city = false;
		var companyName = false;
		var file = false;
		var message = false;

		if ("contact_form" == action) {
			firstName = this.form.querySelectorAll("[name=first_name]")[0];
			lastName = this.form.querySelectorAll("[name=last_name]")[0];
			phoneNumber = this.form.querySelectorAll("[name=phone_number]")[0];
			city = this.form.querySelectorAll("[name=city]")[0];
			companyName = this.form.querySelectorAll("[name=company_name]")[0];
			file = this.form.querySelectorAll("[name=attachment_file]")[0];
			message = this.form.querySelectorAll("[name=message]")[0];
		}
		const formData = new FormData();
		formData.append("action", action);

		if (email) {
			formData.append("email", email.value);
		}

		if (receiverEmail) {
			formData.append("receiver_email", receiverEmail.value);
		}

		if (privacyPolicy) {
			formData.append("privacy_policy", privacyPolicy.value);
		}

		if ("contact_form" == action) {
			if (firstName) {
				formData.append("first_name", firstName.value);
			}

			if (lastName) {
				formData.append("last_name", lastName.value);
			}

			if (phoneNumber) {
				formData.append("phone_number", phoneNumber.value);
			}

			if (city) {
				formData.append("city", city.value);
			}

			if (companyName) {
				formData.append("company_name", companyName.value);
			}

			if (file) {
				formData.append("attachment_file", file.files[0]);
			}

			if (message) {
				formData.append("message", message.value);
			}

			if (messageIsRequired) {
				formData.append("user_message_required", messageIsRequired.value);
			}

			if (currentLanguage) {
				formData.append("current_language", currentLanguage);
			}

			if (defaultLanguage) {
				formData.append("default_language", defaultLanguage);
			}
		}

		// Disable and animate the submit button
		evt.srcElement.disabled = true;
		evt.srcElement.classList.add("validating");

		// Recaptcha validation
		grecaptcha.ready(() => {
			// eslint-disable-line
			grecaptcha.execute(googleRecaptchaWebKey, { action }).then((token) => {
				// eslint-disable-line
				if (token) {
					formData.append("token", token);
				}

				// Ajax form submit
				const xmlhttp = window.ajaxReq();
				const url = encodeURI(scriptsAjaxVariables.ajax_url); // eslint-disable-line
				xmlhttp.open("POST", url, true); // set true for async, false for sync request
				xmlhttp.send(formData); // or null, if no parameters are passed

				// Validation
				xmlhttp.onreadystatechange = () => {
					if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
						try {
							const data = JSON.parse(xmlhttp.responseText);

							// Hide previous errors
							if ("contact_form" == action) {
								if (firstName) {
									firstName.classList.remove("show-error");
								}
								if (lastName) {
									lastName.classList.remove("show-error");
								}
							}
							if (email) {
								email.classList.remove("show-error");
							}
							if (privacyPolicy) {
								privacyPolicy.classList.remove("show-error");
							}
							if (phoneNumber) {
								phoneNumber.classList.remove("show-error");
							}

							if (message) {
								message.classList.remove("show-error");
							}

							if (file) {
								file.classList.remove("show-error");
							}

							if (data.validation === false) {
								// Show errors
								if (data.email) {
									email.classList.add("show-error");
								}
								if (data.privacy_policy) {
									privacyPolicy.classList.add("show-error");
								}
								if ("contact_form" == action) {
									if (data.first_name) {
										firstName.classList.add("show-error");
									}
									if (data.last_name) {
										lastName.classList.add("show-error");
									}
									if (data.phone_number) {
										phoneNumber.classList.add("show-error");
									}
									if (data.message) {
										message.classList.add("show-error");
									}
									if (data.file) {
										file.classList.add("show-error");
									}
								}

								// Scroll to the first error.
								const elementsWithError =
									evt.srcElement.form.getElementsByClassName("show-error");
								elementsWithError[0].scrollIntoView({
									block: "center",
									behavior: "smooth",
								});
							} else if (data.success === false) {
								// Error notification message
								notificationMessage.innerHTML = errorMessage.value;

								// Show notification
								notificationsBanner.classList.add("active");
							} else {
								// Success notification message
								notificationMessage.innerHTML = successMessage.value;

								// Clear the form
								evt.srcElement.form.reset();

								// Show notification
								notificationsBanner.classList.add("active");
							}

							// Enable and stop the submit button animation
							evt.srcElement.disabled = false;
							evt.srcElement.classList.remove("validating");
						} catch (error) {
							// Show notification
							notificationsBanner.classList.add("active");
						}
					}
				};
			});
		});
	}

	closeNotificationBanner(evt) {
		evt.preventDefault();
		evt.srcElement.parentNode.classList.remove("active");
	}
}

export default Forms;
