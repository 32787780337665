/* eslint-disable no-undef, no-unused-vars, class-methods-use-this, prefer-destructuring   */
import Forms from "../../lib/forms";

/**
 * Newsletter form handling class.
 *
 * @see template-parts/components/newsletter-form.php
 * @see lib/form/newsletter-form.php
 */
class NewsletterForm extends Forms {
	init() {
		this.newsletterPopup = document.getElementById("newsletter-modal");
		if (this.newsletterPopup) {
			const newsletterPopupCloseButton = document.getElementById(
				"close-newsletter-modal"
			);

			// Close notificacion banner
			newsletterPopupCloseButton.addEventListener("click", this.closePopup);

			// Submit form
			if (this.newsletterPopup) {
				this.newForm(this.newsletterPopup);
				window.nl = this;
			}
		}
	}

	closePopup(evt) {
		evt.preventDefault();

		// Close popup
		evt.srcElement.closest("#newsletter-modal").classList.remove("active");

		// Enable scroll
		window.disableScroll.enableScroll();
	}
}

export default NewsletterForm;
