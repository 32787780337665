/* eslint-disable func-names, no-unused-expressions, no-undef, no-unused-vars, no-var, no-multi-assign, prefer-spread, prefer-rest-params, spaced-comment, no-underscore-dangle, lines-around-directive, strict, class-methods-use-this, prefer-destructuring, no-param-reassign */
import addScript from "tartemeringuee/utils/addscript";

export const googletagmanagerlServices = (
	googletagmanagerId,
	configOptions = {},
	more = () => true
) => ({
	key: "googletagmanager",
	type: "api",
	name: "Google Tag Manager",
	needConsent: true,
	uri: "https://policies.google.com/privacy",
	cookies: [
		"_ga",
		"_gat",
		"__utma",
		"__utmb",
		"__utmc",
		"__utmt",
		"__utmz",
		"__gads",
		"_drt_",
		"FLC",
		"exchange_uid",
		"id",
		"fc",
		"rrs",
		"rds",
		"rv",
		"uid",
		"UIDR",
		"UID",
		"clid",
		"ipinfo",
		"acs",
	],
	init: () => {
		"use strict";

		if (googletagmanagerId === undefined) {
			return;
		}
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			"gtm.start": new Date().getTime(),
			event: "gtm.js",
		});

		window.dataLayer.push("consent", "update", {
			ad_storage: "granted",
			analytics_storage: "granted",
		});
	},
	accept: () => {
		if (window.location.hostname === "clariship.com") {
			// Add body script.
			const noscriptEl = document.createElement("noscript");
			const iframeEl = document.createElement("iframe");
			iframeEl.src = `https://www.googletagmanager.com/ns.html?id=${googletagmanagerId}`;
			iframeEl.height = "0";
			iframeEl.width = "0";
			iframeEl.style.display = "none";
			iframeEl.style.visibility = "hidden";

			noscriptEl.appendChild(iframeEl);
			document.body.prepend(noscriptEl);

			// Load script and attach to header (handled by addScript function).
			addScript(
				`https://www.googletagmanager.com/gtm.js?id=${googletagmanagerId}`
			);
		}
		more();
	},
	refuse: () => true,
});

export default googletagmanagerlServices;
