/* eslint-disable class-methods-use-this */

// Import libraries
import LazyLoading from "./lib/lazy-loading";
import Lottie from "./lib/lottie";

// Import components
import Cta from "./template-parts/components/cta";
import Filters from "./template-parts/components/filters";
import Newsletter from "./template-parts/components/newsletter";
import Pagination from "./template-parts/components/pagination";

// Import popups
import NewsletterForm from "./template-parts/popups/newsletter-form";

// Import sections
import ContactForm from "./template-parts/sections/contact-form";
import SliderGallery from "./template-parts/sections/slider-gallery";

export default class ModulesLoader {
	constructor(el = document) {
		this.el = el;
	}

	loadModules() {
		// Load libraries
		window.lazyLoading = new LazyLoading();
		window.lottie = new Lottie();

		// Load components
		window.cta = new Cta();
		window.filters = new Filters();
		window.Newsletter = new Newsletter();
		window.pagination = new Pagination();

		// Load popups
		window.newsletterForm = new NewsletterForm();

		// Load sections
		window.contactForm = new ContactForm();
		window.map = new Map();
		window.sliderGallery = new SliderGallery();
	}
}
