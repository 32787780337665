/* eslint-disable class-methods-use-this, prefer-destructuring, no-undef, no-shadow, no-unused-vars */
class Newsletter {
	constructor() {
		this.newsletterShortForms =
			document.getElementsByClassName("js-newsletter");
		if (this.newsletterShortForms) {
			this.init();
		}
	}

	init() {
		Array.prototype.forEach.call(
			this.newsletterShortForms,
			(newsletterShortForm) => {
				newsletterShortForm.addEventListener(
					"submit",
					this.openNewsletterPopup
				);
			}
		);
	}

	openNewsletterPopup(evt) {
		const newsletterPopup = document.getElementById("newsletter-modal");

		// Prevent default form submit
		evt.preventDefault();

		// Populate newsletter form email field
		const email = newsletterPopup.getElementsByClassName("email")[0];
		email.value =
			evt.srcElement.parentNode.getElementsByClassName(
				"newsletter__input"
			)[0].value;

		// Block scroll
		window.disableScroll.disableScroll();

		// Open newsletter form popup
		newsletterPopup.classList.add("active");
	}
}

export default Newsletter;
